<template>
  <!--对账资料模块-->
  <div>
    <!--开票资料-->
    <el-row>
      <el-card shadow="never">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              开票资料
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}yunli:make:save`]">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">新增开票资料</span>
              </el-button>
            </div>
          </div>
        </div>

        <!--数据表格-->
        <div class="dataList" style="margin-top: 20px;">
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}yunli:make:put`]">编辑</el-link>
              <el-popconfirm title="确定执行此操作吗？" @confirm="kaipiaodel(scope.row)">
                <el-link slot="reference" :underline="false" type="info" v-auths="[`${$config.uniquePrefix}yunli:make:delete`]">删除</el-link>
              </el-popconfirm>
            </template>
          </my-table>
        </div>
      </el-card>
    </el-row>


    <!--收款资料-->
    <el-row style="margin-top: 20px;">
      <el-card shadow="never">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              收款资料
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="openShowkuan(null)" v-auths="[`${$config.uniquePrefix}yunli:gathering:save`]">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle">新增收款资料</span>
              </el-button>
            </div>
          </div>
        </div>

        <!--数据表格-->
        <div class="dataList" style="margin-top: 20px;">
          <my-table
              ref="myTable"
              :columns="columns1"
              :tableData="List1"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total1"
              :customsFromWhere="where1"
              :loading="loading1"
              @select="select1"
              @ChangeSize="ChangeSize1"
              @currentChange="currentChange1"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openShowkuan(scope.row)" v-auths="[`${$config.uniquePrefix}yunli:gathering:put`]">编辑</el-link>
              <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                <el-link slot="reference" :underline="false" v-auths="[`${$config.uniquePrefix}yunli:gathering:delete`]">删除</el-link>
              </el-popconfirm>
            </template>
          </my-table>
        </div>
      </el-card>
    </el-row>

    <!--开票资料弹窗-->
    <Edit :id="id" :data="current" :visible.sync="showEdit" @done1="getList"/>

    <!--收款资料弹窗-->
    <dshoukuan-edit :id="id" :data="showkuancurrent" :visible.sync="showShowkuan" @done="getList1"/>

  </div>
</template>

<script>
import Edit from './components/edit.vue'
import DshoukuanEdit from './components/dshoukuan-edit.vue'

// 引入的接口
import {Deldel_invoice, Delproceeds, getinvoice, getproceeds} from "@/api/yunli";
// 权限
import {ShowTable} from '@/utils'


export default {
  props:{
    id:Number
  },
  components:{
    Edit,
    DshoukuanEdit
  },
  data(){
    return{
      // 搜索条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "公司名称",
          prop: "company_name",
          isShow: true
        },
        {
          label: "开票税号",
          prop: "tax_number",
          isShow: true
        },
        {
          label: "开户银行",
          prop: "deposit_bank",
          isShow: true
        },
        {
          label: "银行账号",
          prop: "bank_number",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "企业地址",
          prop: "enterprise_address",
          isShow: true
        },
        {
          label: "邮寄地址",
          prop: "delivery_address",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],
      //开票资料
      current:null,
      showEdit:false,


      // 收款资料表格数据
      // 搜索条件
      where1:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "开户行",
          prop: "tax_number",
          isShow: true
        },
        {
          label: "户名",
          prop: "bank_number",
          isShow: true
        },
        {
          label: "账号",
          prop: "account_holder",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //收款资料
      showkuancurrent:null,
      showShowkuan:false,


    }
  },

  mounted() {
    this.getList();

    //获取收款资料
    this.getList1();
  },

  methods:{
    // 点击开票按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 获取列表
    getList(){
      getinvoice(this.id,this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$message.error(res.msg);
        this.loading = false;
      })
    },

    //获取收款资料
    getList1(){
      getproceeds(this.id,this.where1).then(async (res) => {
        this.total1 = res.data.count;
        this.List1 = res.data.list;
        this.loading1 = false;
      }).catch((res) => {
        this.$message.error(res.msg);
        this.loading1 = false;
      })
    },


    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },



    // 收款资料表格事件
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    openShowkuan(row){
      this.showkuancurrent = row;
      this.showShowkuan = true;
    },

    // 开票资料删除
    kaipiaodel(row){
      let where = {
        id: row.id
      }
      Deldel_invoice(this.id,where).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


    // 删除
    del(row){
      console.log(row);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      let where = {
        id:row.id
      }
      Delproceeds(this.id,where).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList1();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


  }

}
</script>

<style lang="scss" scoped>
.content{

}
.dataList{
  margin-top: 10px;
}
</style>
